/* Primary color */
$primary-color: #f84914;
$primary-front-color: white;

$secondary-color: #fb6732;

$background-color: #f0f0f0;

$success-color: #94c23c;
$success-hover-color: darken($success-color, 10%);

$error-color: #e20a16;
$error-hover-color: darken($error-color, 10%);

$info-color: #00c0ef;
$info-hover-color: darken($info-color, 10%);

$warning-color: #f39c12;
$warning-hover-color: darken($warning-color, 10%);

$link-color: #f84914;
