@import "../../layout/scss/colors";
@import "../../layout/scss/buttons";
@import "../../layout/scss/alerts";

$left-side-color: white;
$logotype-background-color: $primary-color;

.content-wrapper {
  background: $background-color;
}

.avatar{
  background-color: $background-color;
}

.progress-bar {
  background-color: $primary-color !important;
  border-color: darken($primary-color, 20%) !important;
  color: lighten($primary-color, 70%) !important;
}

.fc-toolbar .fc-today-button{
  background-color: $primary-color !important;
  border-color: $primary-color !important;
  color: white;
  &:hover,
  &:active,
  &:focus{
    background-color: darken($primary-color, 5%) !important;
    border-color: darken($primary-color, 5%) !important;
  }
}

.media.sell .conditions {
  color: darken($primary-color, 20%) !important;
}

a {
  color: $link-color;
  &:hover,
  &:active,
  &:focus{
    color: darken($link-color, 20%);
  }
}

.media.sell i {
  color: $primary-color;
}

.media.sell a i {
  color: $link-color;
}

.nav-tabs-custom > .nav-tabs > li.active {
  border-top-color: $primary-color;
}

.dashboard-box .media-left .fa {
  color: $primary-color;
}

.services-payment > .form-group > .btn-group > a.btn.active.btn-primary {
    background-color: $primary-color !important;
    border: 1px solid darken($primary-color, 20%);
    &:hover,
    &:active,
    &:focus{
        background-color: $primary-color !important;
    }
}
